<template>
  <div class="col-md-8 col-lg-6 ml-auto mr-auto">
    <form class="form" @submit.prevent="handleSubmit">
      <div class="card card-login">
        <div class="card-header ">
          <div class="card-header ">
            <h3 class="header text-center">
              <translate>Choose your password</translate>
            </h3>
          </div>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="card-body">
            <FormInput
              field="password"
              type="password"
              v-model="payload.password"
              :label="$gettext('Password')"
              required
            />
            <FormInput
              field="password_confirmation"
              type="password"
              v-model="payload.password_confirmation"
              :label="$gettext('Password Confirmation')"
              required
            />
          </div>
          <div class="card-footer text-center">
            <SubmitButton
              :label="$gettext('Submit')"
              class="btn pmd-ripple-effect btn-primary btn-block ml-0"
            />
          </div>
        </form>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import { checkSecureLink } from "@/router/hooks";
import SubmitButton from "@/components/SubmitButton";
export default {
  components: { SubmitButton, FormInput },
  props: {
    selector: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    payload: { password: "", password_confirmation: "" }
  }),
  beforeRouteEnter(to, from, next) {
    checkSecureLink("user_invitation", to, next);
  },

  methods: {
    ...mapActions("me", ["acceptInvitation"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      const payload = {
        ...this.payload,
        selector: this.selector,
        token: this.token
      };
      this.submit(() => this.acceptInvitation(payload)).then(() => {
        this.$router.push({ name: "dashboard" });
      });
    }
  }
};
</script>
